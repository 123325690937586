// Filename: components/Videos.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Videos.css';

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [visibleVideoId, setVisibleVideoId] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('/api/videos');
        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const toggleVideoVisibility = (id) => {
    setVisibleVideoId(visibleVideoId === id ? null : id);
  };

  return (
    <div className="videos-container">
      <h1 className="videos-title">Myndbönd</h1>
      {videos.map((video) => (
        <div key={video.id} className="video-item">
          <div className="video-header">
          <h3>{video.title ? video.title : 'Untitled Video'}</h3>
            <button onClick={() => toggleVideoVisibility(video.id)}>
              {visibleVideoId === video.id ? 'Fela' : 'Skoða'}
            </button>
          </div>
          {visibleVideoId === video.id && (
            <div className="video-viewer">
              <video controls>
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Videos;
