import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; // Import Navigate
import Home from "./components/Home";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import Reviews from "./components/Reviews";
import Videos from "./components/Videos";
import ReadingMaterial from "./components/ReadingMaterial";
import ExtraMaterial from "./components/ExtraMaterial";
import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/AdminDashboard";
import AuthMiddleware from "./components/AuthMiddleware";
import MainLayout from "./components/MainLayout";
import DashboardLayout from "./components/DashboardLayout";
import WriteReviews from "./components/WriteReviews";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main Site Layout */}
        <Route element={<MainLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/reading-material" element={<ReadingMaterial />} />
          <Route path="/extra-material" element={<ExtraMaterial />} />
          <Route path="/write-reviews" element={<WriteReviews />} />
        </Route>

        {/* Regular User Dashboard Layout */}
        <Route
          path="/dashboard"
          element={
            <AuthMiddleware>
              <DashboardLayout />
            </AuthMiddleware>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="videos" element={<Videos />} />
          <Route path="reading-material" element={<ReadingMaterial />} />
          <Route path="extra-material" element={<ExtraMaterial />} />
          <Route path="write-reviews" element={<WriteReviews />} />
        </Route>

        {/* Admin Dashboard Layout */}
        <Route
          path="/admin-dashboard"
          element={
            <AuthMiddleware>
              <AdminDashboard />
            </AuthMiddleware>
          }
        />

        {/* Redirect root to /home */}
        <Route path="/" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
