import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import './Reviews.css'; // Import the shared CSS file
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Reviews = () => {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch('/api/approved-reviews', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (response.ok) {
                    const data = await response.json();
                    setReviews(data);
                } else {
                    const errorData = await response.json();
                    setError(errorData.error);
                }
            } catch (error) {
                console.error('Error fetching approved reviews:', error);
                setError('Error fetching approved reviews');
            }
        };

        fetchReviews();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false, // Disable adaptive height to make the carousel consistent in size
    };

    return (
        <div className="form-container">
            <h1 className="form-title">Umsagnir</h1>
            {error && <p className="error-message">{error}</p>}
            {reviews.length > 0 ? (
                <Slider {...settings}>
                    {reviews.map((review, index) => (
                        <div key={index} className="review-item">
                            <p><strong>{review.name}:</strong> {review.review}</p>
                            <p className="review-timestamp">{new Date(review.timestamp).toLocaleString()}</p>
                        </div>
                    ))}
                </Slider>
            ) : (
                <p>Engar umsagnir</p>
            )}
        </div>
    );
};

export default Reviews;
